import _d3Dispatch from "d3-dispatch";
import _d3Drag from "d3-drag";
import _d3Interpolate from "d3-interpolate";
import _d3Selection from "d3-selection";
import _d3Transition from "d3-transition";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// https://d3js.org/d3-zoom/ Version 1.6.0. Copyright 2017 Mike Bostock.
(function (global, factory) {
  factory(exports, _d3Dispatch, _d3Drag, _d3Interpolate, _d3Selection, _d3Transition);
})(exports, function (exports, d3Dispatch, d3Drag, d3Interpolate, d3Selection, d3Transition) {
  'use strict';

  var constant = function (x) {
    return function () {
      return x;
    };
  };

  function ZoomEvent(target, type, transform) {
    (this || _global).target = target;
    (this || _global).type = type;
    (this || _global).transform = transform;
  }

  function Transform(k, x, y) {
    (this || _global).k = k;
    (this || _global).x = x;
    (this || _global).y = y;
  }

  Transform.prototype = {
    constructor: Transform,
    scale: function (k) {
      return k === 1 ? this || _global : new Transform((this || _global).k * k, (this || _global).x, (this || _global).y);
    },
    translate: function (x, y) {
      return x === 0 & y === 0 ? this || _global : new Transform((this || _global).k, (this || _global).x + (this || _global).k * x, (this || _global).y + (this || _global).k * y);
    },
    apply: function (point) {
      return [point[0] * (this || _global).k + (this || _global).x, point[1] * (this || _global).k + (this || _global).y];
    },
    applyX: function (x) {
      return x * (this || _global).k + (this || _global).x;
    },
    applyY: function (y) {
      return y * (this || _global).k + (this || _global).y;
    },
    invert: function (location) {
      return [(location[0] - (this || _global).x) / (this || _global).k, (location[1] - (this || _global).y) / (this || _global).k];
    },
    invertX: function (x) {
      return (x - (this || _global).x) / (this || _global).k;
    },
    invertY: function (y) {
      return (y - (this || _global).y) / (this || _global).k;
    },
    rescaleX: function (x) {
      return x.copy().domain(x.range().map((this || _global).invertX, this || _global).map(x.invert, x));
    },
    rescaleY: function (y) {
      return y.copy().domain(y.range().map((this || _global).invertY, this || _global).map(y.invert, y));
    },
    toString: function () {
      return "translate(" + (this || _global).x + "," + (this || _global).y + ") scale(" + (this || _global).k + ")";
    }
  };
  var identity = new Transform(1, 0, 0);
  transform.prototype = Transform.prototype;

  function transform(node) {
    return node.__zoom || identity;
  }

  function nopropagation() {
    d3Selection.event.stopImmediatePropagation();
  }

  var noevent = function () {
    d3Selection.event.preventDefault();
    d3Selection.event.stopImmediatePropagation();
  }; // Ignore right-click, since that should open the context menu.


  function defaultFilter() {
    return !d3Selection.event.button;
  }

  function defaultExtent() {
    var e = this || _global,
        w,
        h;

    if (e instanceof SVGElement) {
      e = e.ownerSVGElement || e;
      w = e.width.baseVal.value;
      h = e.height.baseVal.value;
    } else {
      w = e.clientWidth;
      h = e.clientHeight;
    }

    return [[0, 0], [w, h]];
  }

  function defaultTransform() {
    return (this || _global).__zoom || identity;
  }

  function defaultWheelDelta() {
    return -d3Selection.event.deltaY * (d3Selection.event.deltaMode ? 120 : 1) / 500;
  }

  function defaultTouchable() {
    return "ontouchstart" in (this || _global);
  }

  var zoom = function () {
    var filter = defaultFilter,
        extent = defaultExtent,
        wheelDelta = defaultWheelDelta,
        touchable = defaultTouchable,
        k0 = 0,
        k1 = Infinity,
        x0 = -k1,
        x1 = k1,
        y0 = x0,
        y1 = x1,
        duration = 250,
        interpolate = d3Interpolate.interpolateZoom,
        gestures = [],
        listeners = d3Dispatch.dispatch("start", "zoom", "end"),
        touchstarting,
        touchending,
        touchDelay = 500,
        wheelDelay = 150,
        clickDistance2 = 0;

    function zoom(selection) {
      selection.property("__zoom", defaultTransform).on("wheel.zoom", wheeled).on("mousedown.zoom", mousedowned).on("dblclick.zoom", dblclicked).filter(touchable).on("touchstart.zoom", touchstarted).on("touchmove.zoom", touchmoved).on("touchend.zoom touchcancel.zoom", touchended).style("touch-action", "none").style("-webkit-tap-highlight-color", "rgba(0,0,0,0)");
    }

    zoom.transform = function (collection, transform$$1) {
      var selection = collection.selection ? collection.selection() : collection;
      selection.property("__zoom", defaultTransform);

      if (collection !== selection) {
        schedule(collection, transform$$1);
      } else {
        selection.interrupt().each(function () {
          gesture(this || _global, arguments).start().zoom(null, typeof transform$$1 === "function" ? transform$$1.apply(this || _global, arguments) : transform$$1).end();
        });
      }
    };

    zoom.scaleBy = function (selection, k) {
      zoom.scaleTo(selection, function () {
        var k0 = (this || _global).__zoom.k,
            k1 = typeof k === "function" ? k.apply(this || _global, arguments) : k;
        return k0 * k1;
      });
    };

    zoom.scaleTo = function (selection, k) {
      zoom.transform(selection, function () {
        var e = extent.apply(this || _global, arguments),
            t0 = (this || _global).__zoom,
            p0 = centroid(e),
            p1 = t0.invert(p0),
            k1 = typeof k === "function" ? k.apply(this || _global, arguments) : k;
        return constrain(translate(scale(t0, k1), p0, p1), e);
      });
    };

    zoom.translateBy = function (selection, x, y) {
      zoom.transform(selection, function () {
        return constrain((this || _global).__zoom.translate(typeof x === "function" ? x.apply(this || _global, arguments) : x, typeof y === "function" ? y.apply(this || _global, arguments) : y), extent.apply(this || _global, arguments));
      });
    };

    zoom.translateTo = function (selection, x, y) {
      zoom.transform(selection, function () {
        var e = extent.apply(this || _global, arguments),
            t = (this || _global).__zoom,
            p = centroid(e);
        return constrain(identity.translate(p[0], p[1]).scale(t.k).translate(typeof x === "function" ? -x.apply(this || _global, arguments) : -x, typeof y === "function" ? -y.apply(this || _global, arguments) : -y), e);
      });
    };

    function scale(transform$$1, k) {
      k = Math.max(k0, Math.min(k1, k));
      return k === transform$$1.k ? transform$$1 : new Transform(k, transform$$1.x, transform$$1.y);
    }

    function translate(transform$$1, p0, p1) {
      var x = p0[0] - p1[0] * transform$$1.k,
          y = p0[1] - p1[1] * transform$$1.k;
      return x === transform$$1.x && y === transform$$1.y ? transform$$1 : new Transform(transform$$1.k, x, y);
    }

    function constrain(transform$$1, extent) {
      var dx0 = transform$$1.invertX(extent[0][0]) - x0,
          dx1 = transform$$1.invertX(extent[1][0]) - x1,
          dy0 = transform$$1.invertY(extent[0][1]) - y0,
          dy1 = transform$$1.invertY(extent[1][1]) - y1;
      return transform$$1.translate(dx1 > dx0 ? (dx0 + dx1) / 2 : Math.min(0, dx0) || Math.max(0, dx1), dy1 > dy0 ? (dy0 + dy1) / 2 : Math.min(0, dy0) || Math.max(0, dy1));
    }

    function centroid(extent) {
      return [(+extent[0][0] + +extent[1][0]) / 2, (+extent[0][1] + +extent[1][1]) / 2];
    }

    function schedule(transition, transform$$1, center) {
      transition.on("start.zoom", function () {
        gesture(this || _global, arguments).start();
      }).on("interrupt.zoom end.zoom", function () {
        gesture(this || _global, arguments).end();
      }).tween("zoom", function () {
        var that = this || _global,
            args = arguments,
            g = gesture(that, args),
            e = extent.apply(that, args),
            p = center || centroid(e),
            w = Math.max(e[1][0] - e[0][0], e[1][1] - e[0][1]),
            a = that.__zoom,
            b = typeof transform$$1 === "function" ? transform$$1.apply(that, args) : transform$$1,
            i = interpolate(a.invert(p).concat(w / a.k), b.invert(p).concat(w / b.k));
        return function (t) {
          if (t === 1) t = b; // Avoid rounding error on end.
          else {
              var l = i(t),
                  k = w / l[2];
              t = new Transform(k, p[0] - l[0] * k, p[1] - l[1] * k);
            }
          g.zoom(null, t);
        };
      });
    }

    function gesture(that, args) {
      for (var i = 0, n = gestures.length, g; i < n; ++i) {
        if ((g = gestures[i]).that === that) {
          return g;
        }
      }

      return new Gesture(that, args);
    }

    function Gesture(that, args) {
      (this || _global).that = that;
      (this || _global).args = args;
      (this || _global).index = -1;
      (this || _global).active = 0;
      (this || _global).extent = extent.apply(that, args);
    }

    Gesture.prototype = {
      start: function () {
        if (++(this || _global).active === 1) {
          (this || _global).index = gestures.push(this || _global) - 1;
          this.emit("start");
        }

        return this || _global;
      },
      zoom: function (key, transform$$1) {
        if ((this || _global).mouse && key !== "mouse") (this || _global).mouse[1] = transform$$1.invert((this || _global).mouse[0]);
        if ((this || _global).touch0 && key !== "touch") (this || _global).touch0[1] = transform$$1.invert((this || _global).touch0[0]);
        if ((this || _global).touch1 && key !== "touch") (this || _global).touch1[1] = transform$$1.invert((this || _global).touch1[0]);
        (this || _global).that.__zoom = transform$$1;
        this.emit("zoom");
        return this || _global;
      },
      end: function () {
        if (--(this || _global).active === 0) {
          gestures.splice((this || _global).index, 1);
          (this || _global).index = -1;
          this.emit("end");
        }

        return this || _global;
      },
      emit: function (type) {
        d3Selection.customEvent(new ZoomEvent(zoom, type, (this || _global).that.__zoom), listeners.apply, listeners, [type, (this || _global).that, (this || _global).args]);
      }
    };

    function wheeled() {
      if (!filter.apply(this || _global, arguments)) return;
      var g = gesture(this || _global, arguments),
          t = (this || _global).__zoom,
          k = Math.max(k0, Math.min(k1, t.k * Math.pow(2, wheelDelta.apply(this || _global, arguments)))),
          p = d3Selection.mouse(this || _global); // If the mouse is in the same location as before, reuse it.
      // If there were recent wheel events, reset the wheel idle timeout.

      if (g.wheel) {
        if (g.mouse[0][0] !== p[0] || g.mouse[0][1] !== p[1]) {
          g.mouse[1] = t.invert(g.mouse[0] = p);
        }

        clearTimeout(g.wheel);
      } // If this wheel event won’t trigger a transform change, ignore it.
      else if (t.k === k) return; // Otherwise, capture the mouse point and location at the start.
        else {
            g.mouse = [p, t.invert(p)];
            d3Transition.interrupt(this || _global);
            g.start();
          }

      noevent();
      g.wheel = setTimeout(wheelidled, wheelDelay);
      g.zoom("mouse", constrain(translate(scale(t, k), g.mouse[0], g.mouse[1]), g.extent));

      function wheelidled() {
        g.wheel = null;
        g.end();
      }
    }

    function mousedowned() {
      if (touchending || !filter.apply(this || _global, arguments)) return;
      var g = gesture(this || _global, arguments),
          v = d3Selection.select(d3Selection.event.view).on("mousemove.zoom", mousemoved, true).on("mouseup.zoom", mouseupped, true),
          p = d3Selection.mouse(this || _global),
          x0 = d3Selection.event.clientX,
          y0 = d3Selection.event.clientY;
      d3Drag.dragDisable(d3Selection.event.view);
      nopropagation();
      g.mouse = [p, (this || _global).__zoom.invert(p)];
      d3Transition.interrupt(this || _global);
      g.start();

      function mousemoved() {
        noevent();

        if (!g.moved) {
          var dx = d3Selection.event.clientX - x0,
              dy = d3Selection.event.clientY - y0;
          g.moved = dx * dx + dy * dy > clickDistance2;
        }

        g.zoom("mouse", constrain(translate(g.that.__zoom, g.mouse[0] = d3Selection.mouse(g.that), g.mouse[1]), g.extent));
      }

      function mouseupped() {
        v.on("mousemove.zoom mouseup.zoom", null);
        d3Drag.dragEnable(d3Selection.event.view, g.moved);
        noevent();
        g.end();
      }
    }

    function dblclicked() {
      if (!filter.apply(this || _global, arguments)) return;
      var t0 = (this || _global).__zoom,
          p0 = d3Selection.mouse(this || _global),
          p1 = t0.invert(p0),
          k1 = t0.k * (d3Selection.event.shiftKey ? 0.5 : 2),
          t1 = constrain(translate(scale(t0, k1), p0, p1), extent.apply(this || _global, arguments));
      noevent();
      if (duration > 0) d3Selection.select(this || _global).transition().duration(duration).call(schedule, t1, p0);else d3Selection.select(this || _global).call(zoom.transform, t1);
    }

    function touchstarted() {
      if (!filter.apply(this || _global, arguments)) return;
      var g = gesture(this || _global, arguments),
          touches = d3Selection.event.changedTouches,
          started,
          n = touches.length,
          i,
          t,
          p;
      nopropagation();

      for (i = 0; i < n; ++i) {
        t = touches[i], p = d3Selection.touch(this || _global, touches, t.identifier);
        p = [p, (this || _global).__zoom.invert(p), t.identifier];
        if (!g.touch0) g.touch0 = p, started = true;else if (!g.touch1) g.touch1 = p;
      } // If this is a dbltap, reroute to the (optional) dblclick.zoom handler.


      if (touchstarting) {
        touchstarting = clearTimeout(touchstarting);

        if (!g.touch1) {
          g.end();
          p = d3Selection.select(this || _global).on("dblclick.zoom");
          if (p) p.apply(this || _global, arguments);
          return;
        }
      }

      if (started) {
        touchstarting = setTimeout(function () {
          touchstarting = null;
        }, touchDelay);
        d3Transition.interrupt(this || _global);
        g.start();
      }
    }

    function touchmoved() {
      var g = gesture(this || _global, arguments),
          touches = d3Selection.event.changedTouches,
          n = touches.length,
          i,
          t,
          p,
          l;
      noevent();
      if (touchstarting) touchstarting = clearTimeout(touchstarting);

      for (i = 0; i < n; ++i) {
        t = touches[i], p = d3Selection.touch(this || _global, touches, t.identifier);
        if (g.touch0 && g.touch0[2] === t.identifier) g.touch0[0] = p;else if (g.touch1 && g.touch1[2] === t.identifier) g.touch1[0] = p;
      }

      t = g.that.__zoom;

      if (g.touch1) {
        var p0 = g.touch0[0],
            l0 = g.touch0[1],
            p1 = g.touch1[0],
            l1 = g.touch1[1],
            dp = (dp = p1[0] - p0[0]) * dp + (dp = p1[1] - p0[1]) * dp,
            dl = (dl = l1[0] - l0[0]) * dl + (dl = l1[1] - l0[1]) * dl;
        t = scale(t, Math.sqrt(dp / dl));
        p = [(p0[0] + p1[0]) / 2, (p0[1] + p1[1]) / 2];
        l = [(l0[0] + l1[0]) / 2, (l0[1] + l1[1]) / 2];
      } else if (g.touch0) p = g.touch0[0], l = g.touch0[1];else return;

      g.zoom("touch", constrain(translate(t, p, l), g.extent));
    }

    function touchended() {
      var g = gesture(this || _global, arguments),
          touches = d3Selection.event.changedTouches,
          n = touches.length,
          i,
          t;
      nopropagation();
      if (touchending) clearTimeout(touchending);
      touchending = setTimeout(function () {
        touchending = null;
      }, touchDelay);

      for (i = 0; i < n; ++i) {
        t = touches[i];
        if (g.touch0 && g.touch0[2] === t.identifier) delete g.touch0;else if (g.touch1 && g.touch1[2] === t.identifier) delete g.touch1;
      }

      if (g.touch1 && !g.touch0) g.touch0 = g.touch1, delete g.touch1;
      if (g.touch0) g.touch0[1] = (this || _global).__zoom.invert(g.touch0[0]);else g.end();
    }

    zoom.wheelDelta = function (_) {
      return arguments.length ? (wheelDelta = typeof _ === "function" ? _ : constant(+_), zoom) : wheelDelta;
    };

    zoom.filter = function (_) {
      return arguments.length ? (filter = typeof _ === "function" ? _ : constant(!!_), zoom) : filter;
    };

    zoom.touchable = function (_) {
      return arguments.length ? (touchable = typeof _ === "function" ? _ : constant(!!_), zoom) : touchable;
    };

    zoom.extent = function (_) {
      return arguments.length ? (extent = typeof _ === "function" ? _ : constant([[+_[0][0], +_[0][1]], [+_[1][0], +_[1][1]]]), zoom) : extent;
    };

    zoom.scaleExtent = function (_) {
      return arguments.length ? (k0 = +_[0], k1 = +_[1], zoom) : [k0, k1];
    };

    zoom.translateExtent = function (_) {
      return arguments.length ? (x0 = +_[0][0], x1 = +_[1][0], y0 = +_[0][1], y1 = +_[1][1], zoom) : [[x0, y0], [x1, y1]];
    };

    zoom.duration = function (_) {
      return arguments.length ? (duration = +_, zoom) : duration;
    };

    zoom.interpolate = function (_) {
      return arguments.length ? (interpolate = _, zoom) : interpolate;
    };

    zoom.on = function () {
      var value = listeners.on.apply(listeners, arguments);
      return value === listeners ? zoom : value;
    };

    zoom.clickDistance = function (_) {
      return arguments.length ? (clickDistance2 = (_ = +_) * _, zoom) : Math.sqrt(clickDistance2);
    };

    return zoom;
  };

  exports.zoom = zoom;
  exports.zoomTransform = transform;
  exports.zoomIdentity = identity;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const zoom = exports.zoom,
      zoomTransform = exports.zoomTransform,
      zoomIdentity = exports.zoomIdentity,
      __esModule = exports.__esModule;